<template>
   <div class="vendor-container">
     <h4>Become a Vendor</h4>
     <p>Partner with us today and grow your business</p>
     <form class="form row pb-5 mb-5" enctype="multipart/form-data">
       <div class="col-12">
         <label class="mb-1">Business Name &nbsp;</label>
         <span id="errorMsg" v-if="v$.business_name.$error">
           {{ v$.business_name.$errors[0].$message }}
         </span>
         <div class="input-group input-group mb-3">
           <input
             type="text"
             class="form-control form-control-lg"
             aria-label="Sizing example input"
             aria-describedby="inputGroup-sizing-default"
             v-model="value.business_name"
           />
         </div>
       </div>
 
       <div class="col-12 col-md-6">
         <label class="mb-1">Email &nbsp; &nbsp;</label>
         <span id="errorMsg" v-if="v$.email.$error">
           {{ v$.email.$errors[0].$message }}
         </span>
         <div class="input-group input-group mb-3">
           <input
             type="text"
             class="form-control form-control-lg"
             aria-label="Sizing example input"
             aria-describedby="inputGroup-sizing-default"
             v-model="value.email"
           />
         </div>
       </div>
 
         <div class="col-12 col-md-6">
           <label class="mb-1">Phone Number&nbsp;</label>
           <span id="errorMsg" v-if="v$.phone.$error">
             {{ v$.phone.$errors[0].$message }}
           </span>
           <div class="input-group input-group mb-3">
             <input
               type="number"
               class="form-control form-control-lg"
               aria-label="Sizing example input"
               aria-describedby="inputGroup-sizing-lg"
               v-model="value.phone"
             />
           </div>
         </div>
 
         <div class="col-12">
           <label class="mb-1">Address Of Business&nbsp;&nbsp;</label>
           <span id="errorMsg" v-if="v$.business_address.$error">
             {{ v$.business_address.$errors[0].$message }}
           </span>
           <div class="input-group input-group mb-3">
             <input
               type="text"
               class="form-control form-control-lg"
               aria-label="Sizing example input"
               aria-describedby="inputGroup-sizing-lg"
               v-model="value.business_address"
             />
           </div>
         </div>
 
         <div class="col-12 col-md-6 mb-3">
           <label class="mb-1">State&nbsp;&nbsp;</label>
           <span id="errorMsg" v-if="v$.state_id.$error">
             {{ v$.state_id.$errors[0].$message }}
           </span>
           <select
             id="select"
             class="form-select form-control-lg"
             aria-label="Default select example"
             @change="getCity($event)"
             v-model="value.state_id"
           >
             <option
               :key="index"
               :value="state.id"
               v-for="(state, index) in States"
             >
               {{ state.name }}
             </option>
           </select>
         </div>
 
         <div class="col-12 col-md-6">
           <label class="mb-1">City&nbsp;&nbsp;</label>
           <span id="errorMsg" v-if="v$.city_id.$error">
             {{ v$.city_id.$errors[0].$message }}
           </span>
           <select
             id="select"
             :disabled="stateID == ''"
             class="form-select form-control-lg"
             aria-label="Default select example"
             v-model="value.city_id"
           >
             <option
               v-for="(city, index) in Cities"
               :key="index"
               :value="city.id"
             >
               {{ city.name }}
             </option>
           </select>
         </div>
 
         <div class="col-12">
           <label class="mb-1">Submit Business Picture(JPEG or PNG format)</label>
           <div class="input-group input-group mb-3">
             <input
               type="file"
               class="form-control form-control-lg"
               @change="previewFiles"
               multiple
             />
           </div>
         </div>
 
         <div class="col-12">
           <div class="next">
             <Button id="btn" @click="next" btnText="NEXT" />
           </div>
         </div>
     </form>
   </div>
 </template>
 
 <script>
 import Button from "@/components/ReusableUI/SmalllButton.vue";
 import router from "@/router";
 import { reactive, computed } from "vue";
 import { required, email } from "@vuelidate/validators";
 import { mapGetters, mapActions } from "vuex";
 import useVuelidate from "@vuelidate/core";
 
 export default {
   name: "CreateRestaurant",
 
   components: {
     Button,
   },
 
   setup() {
     const value = reactive({
       business_name: "",
       email: "",
       phone: "",
       state_id: "",
       city_id: "",
       category_ids: "",
       business_address: "",
       file: null,
     });
 
     const rules = computed(() => {
       return {
         business_name: { required },
         email: { required, email },
         phone: { required },
         state_id: { required },
         city_id: { required },
         // category_ids,
         business_address: { required },
       };
     });
     const v$ = useVuelidate(rules, value);
     return { value, v$ };
   },
 
   data() {
     return {
       stateID: "",
       States: [],
       Business: [],
       phonenumer: "",
     };
   },
 
   validations() {
     return {
       business_name: { required },
       email: { required, email },
       phone: { required },
       state_id: { required },
       city_id: { required },
       // category_ids,
       business_address: { required },
     };
   },
 
   methods: {
     ...mapGetters({
       States: "general/availableStates",
     }),
 
     ...mapActions({
       getStates: "general/getState",
     }),
 
     // method to get file value
     previewFiles(event) {
       this.file = event.target.files;
     },
 
     next(event) {
       event.preventDefault();
       this.v$.$validate();
       if (!this.v$.$error) {
         const {
           business_name,
           email,
           phone,
           state_id,
           business_address,
           city_id,
           category_ids,
         } = this.value;
         const data = {
           business_name,
           email,
           phone,
           state_id,
           business_address,
           city_id,
           image: this.file[0],
           category_ids,
         };
         this.$store.state.vendor = data;
         router.push({ name: "vendor-food-category" });
       }
     },
 
     // use state ID to get Cities
     getCity(e) {
       this.stateID = e.target.value;
       this.Cities = this.States.find((city) => city.id === this.stateID).cities;
     },
   },
 
   async created() {
     this.States = this.$store.state["general"].states;
     this.getStates()
   },
 };
 </script>
 
 <style scoped>
 #btn {
   margin-top: 4%;
   background-color: rgba(133, 77, 39, 1);
 }
 .vendor-container {
   padding: 0% 5%;
 }
 .vendor-container h4 {
   color: rgba(4, 3, 15, 1);
   padding-top: 5%;
   font-weight: 600;
 }
 
 .vendor-container p {
   padding-top: 2%;
 }
 
 #errorMsg {
   color: red;
 }
 
 form {
   padding-top: 20px;
 }
 
 #input {
   outline: none !important;
   box-shadow: none;
   border-color: rgb(196, 192, 192);
   margin-right: 0px;
 }
 #select {
   outline: none !important;
   box-shadow: none;
   border-color: rgb(196, 192, 192);
   margin-right: 0px;
 }
 
 #input2 {
   outline: none !important;
   box-shadow: none;
   border-color: rgb(196, 192, 192);
 }
 
 #input3 {
   outline: none !important;
   box-shadow: none;
   border-color: rgb(196, 192, 192);
 }
 
 #state {
   width: 53%;
   /* margin-right: 30%; */
 }
 
 #city {
   width: 53%;
   /* margin-right: 30%; */
 }
 
 #state[data-v-eaef24a2] {
   width: 100%;
   /* margin-right: 13%;*/
 }
 
 .form-control {
   outline: none !important;
   box-shadow: none;
   border-color: rgb(196, 192, 192);
 }
 
 .next {
   text-align: right;
 }
 
 @media only screen and (max-width: 700px) {
   #state {
     width: 100%;
   }
 }
 </style>
 